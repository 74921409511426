import React from "react";
import './full-width-book.css';
import { StaticQuery, graphql } from "gatsby";

const FullWidthBook = (props) => {
    const {
        social_title,
        book_title,
        book_subtitle,
        book_button_link,
        book_button_text,
        image
    } = props;
    console.log('FullWidthBook', props)
    return (
        <div className="full-width-book" style={{backgroundImage: `url(${image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`}}>
            <div className="container">
                <div className="full-width-book__social">
                    <h3 className="full-width-book__social-title">{social_title}</h3>
                    <StaticQuery query={graphql`
                        query {
                            strapi {
                                social {
                                    facebook_link
                                    instagram_link
                                    linkedin_link
                                    telephone
                                    youtube_link
                                }
                            }
                        }`}
                        render={
                            data => { 
                            const {
                                facebook_link,
                                instagram_link,
                                linkedin_link,
                                youtube_link
                            } = data.strapi.social;
                            return (
                                <ul className="full-width-book__social-icons">
                                    {facebook_link && (<li>
                                        <a href={facebook_link} className="full-width-book__social-facebook full-width-book__social-icon">Facebook</a>
                                    </li>)}
                                    {instagram_link && (<li>
                                        <a href={instagram_link} className="full-width-book__social-instagram full-width-book__social-icon">Instagram</a>
                                    </li>)}
                                    {youtube_link && (<li>
                                        <a href={youtube_link} className="full-width-book__social-youtube full-width-book__social-icon">YouTube</a>
                                    </li>)}
                                    {linkedin_link && (<li>
                                        <a href={linkedin_link} className="full-width-book__social-linkedin full-width-book__social-icon">LinkedIn</a>
                                    </li>)}
                                </ul>
                            )}
                        }
                    />
                </div>
                <div className="full-width-book__book">
                    <div className="full-width-book__text">
                        <h2 className="full-width-book__title">{book_title}</h2>
                        <p className="full-width-book__subtitle"> {book_subtitle}</p>
                    </div>
                    <p className="full-width-book__button"><a href={book_button_link} className="button">{book_button_text}</a></p>
                </div>
            </div>
        </div>
    )
}

export default FullWidthBook;